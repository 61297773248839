import { withResolvedError } from '@wix/members-area-commons-ts';

import type { FlowApi } from '../../types';
import type { I18nResource } from '../../types/i18n';
import { I18nNamespace } from '../../types/i18n';

const getLocaleDatasetResourcesUrl = (locale = 'en', version = '1.2900.0') =>
  `https://static.parastorage.com/services/locale-dataset-data/${version}/translations/messages_${locale}.json`;

const fetchLocaleDatasetResources = (url: string, flowAPI: FlowApi) => {
  const { httpClient, errorHandler } = flowAPI;

  const requestFn = () => httpClient.get<I18nResource['resources']>(url);

  return withResolvedError(requestFn, errorHandler, {
    errorCodesMap: {},
  });
};

export const getLocaleDatasetResource = async (
  flowAPI: FlowApi,
): Promise<I18nResource> => {
  const locale = flowAPI.translations.config.language;
  const url = getLocaleDatasetResourcesUrl(locale);
  const fallbackUrl = getLocaleDatasetResourcesUrl('en');

  const { data } = await fetchLocaleDatasetResources(url, flowAPI).catch(
    (exception) => {
      flowAPI.sentry?.captureException?.(exception);

      return fetchLocaleDatasetResources(fallbackUrl, flowAPI).catch((e) => {
        flowAPI.sentry?.captureException?.(e);
        return { data: {} };
      });
    },
  );

  return {
    lng: locale,
    ns: I18nNamespace.localeDataset,
    resources: data,
  };
};
